import React from 'react';
import './Heading.scss';
import {Link} from "gatsby";

export default function Heading() {
    return (
        <div className="DigitalMP">
            <Link className="AppName" to="/">DIGITALE MEDIZINPRODUKTE</Link>
            <Link className="GebrAkte" to="/Kontakt">Kontakt</Link>
            <Link className="GebrAkte" to="/Gebrauchstauglichkeitsakte">Gebrauchs&shy;tauglichkeitsakte</Link>
        </div>
    );
}