import React from 'react';
// @ts-ignore
import image from '../../../static/assets/Selfieprobes.png';
import './section8.scss';
import Jana from './Cards/Jana';
import Nicole from './Cards/Nicole';
import { Link } from 'gatsby';

export default function Section8() {

    return (
        <div className="ContainerSection8" id="Kontakt">
            <div className="HeadingSection8">Eure Ansprechpartner</div>
            <div className="ContactPersonsSection">
                <Jana/>
                <Nicole/>
            </div>
            <div className="ContactFormContainer">
                <div className="ContactFormHeading">Oder schreibt uns eine Nachricht über unser Kontaktformular</div>
                <Link className="ContactFormLink" to="/Kontakt">Kontaktformular öffnen</Link>
            </div>
        </div>
    );
}