import React from 'react';
// @ts-ignore
import imageAkquinet from '../../../static/assets/akquinet_logo.png';
// @ts-ignore
import imageHse from '../../../static/assets/HSE_logo.png';
import './section9.scss';
import {navigate} from "gatsby";


export default function Section9() {

    const navigateToImpressum = () => {
        navigate('/Impressum');
    }

    const navigateToPrivacyPolicy = () => {
        navigate('/PrivacyPolicy');
    }

    return (
        <div className="ContentSection9">

            <div className="HeadingBlockRight">
                Dieser Service wird angeboten von:
            </div>

            <div className="CompanyLogos">
                <div className="Logo">
                    <a href="https://akquinet.com/individualsoftware.html" target="_blank">
                        <img className="LogoImage" src={imageAkquinet}/>
                    </a>
                </div>
            </div>

            <div className="BottomLine">
                <div className="EntryBlockLeft">
                    <button className="PageButton" onClick={() => navigateToImpressum()}>Impressum</button>
                </div>
                <div className="EntryBlockLeft">
                    <button className="PageButton" onClick={() => navigateToPrivacyPolicy()}>Datenschutz</button>
                </div>
                <div className="Copyright">
                    2024 AKQUINET. &nbsp; All rights reserved
                </div>
            </div>
        </div>
    );
}