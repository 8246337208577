import React from 'react';
// @ts-ignore
import ContactPerson from './ContactPerson';
// @ts-ignore
import imageHans from '../../../../static/assets/Nicole.png';

export default function Nicole() {
    return (
        <ContactPerson  image={imageHans}
    name="Nicole Charlier"
    company="AKQUINET"
    quali="UX für Medizinprodukteentwicklung"
    email="nicole.charlier@akquinet.de"/>
    )
}