import React from 'react';
// @ts-ignore
import ContactPerson from './ContactPerson';
// @ts-ignore
import imageJana from '../../../../static/assets/Jana.png';

export default function Jana() {
    return (
        <ContactPerson image={imageJana}
                       name="Jana Metz"
                       company="AKQUINET"
                       quali="Qualitäts&shy;management und Vertrieb"
                       email="jana.metz@akquinet.de"/>
    )
}